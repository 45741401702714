.onboarding form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.onboarding h3,
.onboarding h4,
.onboarding p {
  text-align: center;
}
.onboarding h3 {
  max-width: 280px;
  font-size: 23px;
  font-weight: 500;
}
.onboarding p {
  font-size: 14px;
  margin-top: 0;
}
.onboarding .goBack {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.onboarding .goBack svg {
  width: 25px;
  height: 25px;
}
.onboarding .input-control {
  flex: unset;
  width: 100%;
  max-width: 300px;
}
.onboarding .input-control .input-field {
  border-radius: 0;
  height: 55px;
}
.onboarding .input-control input {
  width: 100%;
  text-align: center;
}
.onboarding button {
  font-size: 16px;
  height: 45px;
  width: 140px;
  font-weight: bold;
}

/*# sourceMappingURL=onboarding.css.map */
