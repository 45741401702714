.daysOff {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 20px 40px;
}
.daysOff * {
  font-family: "Poppins", sans-serif;
}
.daysOff img {
  width: 100px;
}
.daysOff::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../assets/blackFriday/daysCover.jpg") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 550px) {
  .daysOff {
    margin: 0 20px;
    max-width: 400px;
  }
  .daysOff::before {
    background-image: url("../../assets/blackFriday/daysCoverV.jpg") !important;
  }
}
.daysOff .head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  padding: 20px;
}
.daysOff .head button {
  background-color: white;
  color: #000;
  border-radius: 0;
  font-size: 18px;
  font-weight: 600;
  width: unset;
  height: unset;
  padding: 5px 12px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .daysOff .head {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.daysOff .ribbon {
  background-color: white;
  padding: 10px 20px;
  width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 550px) {
  .daysOff .ribbon {
    position: relative;
    margin-top: 10vh;
    width: 100px;
  }
}
.daysOff .contentMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 30px;
}
@media screen and (max-width: 550px) {
  .daysOff .contentMain {
    margin-top: 0;
  }
}
.daysOff .containerMain {
  position: relative;
}
.daysOff .context {
  color: white;
  font-size: 6rem;
  font-weight: 500;
  font-family: "Bebas Neue", cursive;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 550px) {
  .daysOff .context {
    font-size: 4rem;
  }
}
.daysOff .basket {
  color: white;
  font-family: "DhanFont", cursive !important;
  font-size: 3rem;
  right: -50px;
  top: 5.1rem;
  position: absolute;
}
@media screen and (max-width: 550px) {
  .daysOff .basket {
    font-size: 2rem;
    right: 0;
    top: 4rem;
  }
}
.daysOff .textBox {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 30px;
  text-align: center;
  font-weight: 300;
}
@media screen and (max-width: 550px) {
  .daysOff .textBox {
    margin-top: 8vh;
    font-size: 0.9rem;
    font-weight: 400;
  }
}
.daysOff .textContent {
  margin-top: 6vh;
  margin-bottom: 5px;
  font-size: 20px;
  color: white;
}
@media screen and (max-width: 550px) {
  .daysOff .textContent {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
.daysOff .playButt {
  border-radius: 50px;
  color: #ec424b;
  background: white;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 5vh;
  margin-bottom: 50px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.daysOff .logo {
  width: 70px;
}

/*# sourceMappingURL=daysOf.css.map */
