@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap);
.mainLayout {
  background-color: #f5f5fb;
  min-height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.mainLayout .sideBar {
  width: 250px;
  min-width: 250px;
  background-color: #ffffff;
  border-right: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;
  position: fixed;
}
.mainLayout .sideBar .logo {
  width: 80px;
}
.mainLayout .sideBar .sideLinks {
  margin-top: 100px;
}
.mainLayout .sideBar .sideLink {
  margin-bottom: 46px;
  font-size: 14px;
  font-weight: 600;
  color: gray;
  transition: 0.3s ease;
}
.mainLayout .sideBar .sideLink .icon-main {
  margin-right: 30px;
}
.mainLayout .sideBar .sideLink.active {
  color: #000000;
}
.mainLayout .sideBar .sideLink:hover {
  color: #333333;
}
.mainLayout .sideBar .sideLink.logout {
  color: #ffbb3c;
}
.mainLayout .navBar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .mainLayout .navBar .mobile {
    display: flex;
  }
}
.mainLayout .navBar .pageTitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.mainLayout .navBar .navLeft {
  min-width: 150px;
}
.mainLayout .navBar .navRight {
  display: flex;
  align-items: center;
}
.mainLayout .navBar .navRight .navItem {
  margin-right: 20px;
}
.mainLayout .navBar .navRight .navItem:last-child {
  margin-right: 0;
}
.mainLayout .navLogo {
  width: 50px;
}
.mainLayout .moreSide {
  display: block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.mainLayout .contentMain {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  min-width: 320px;
}
.mainLayout .mainBar {
  width: calc(100% - 250px);
  position: fixed;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 250px;
  transition: 0.3s ease;
  overflow-y: auto;
}
.mainLayout .mainBar .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
}
.mainLayout .role-switcher {
  margin-left: 50px;
}
.mainLayout .role-switcher .dropdown-content {
  padding: 0 20px;
  background-color: #ffbb3c;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 20px;
  position: relative;
}
.mainLayout .role-switcher .dropdown-content:after {
  content: ">";
  position: absolute;
  right: 5px;
}
.mainLayout .role-switcher .head {
  font-size: 10px;
  text-align: right;
}
.mainLayout .notifier {
  margin-right: 40px;
}
.mainLayout .notifier svg {
  width: 20px;
  height: 20px;
  color: #999999;
}
@media screen and (max-width: 600px) {
  .mainLayout .mobile.closed .sideBar {
    left: -250px;
  }
  .mainLayout .mobile.closed ~ .mainBar {
    left: 0;
    width: 100%;
  }
  .mainLayout .mobile.closed ~ .mainBar .pageTitle {
    font-size: 20px;
  }
  .mainLayout .mobile.closed ~ .mainBar .overlay {
    display: none;
  }
}

/*# sourceMappingURL=mainlayout.css.map */

.lds-dual-ring {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid transparent;
  -webkit-animation: lds-dual-ring 0.5s linear infinite;
          animation: lds-dual-ring 0.5s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=Spinner.css.map */

.global-loader {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
}
.global-loader .inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
}
.global-loader .inner .context {
  font-size: 13px;
  font-weight: bold;
  color: #3da7db;
}
.global-loader.show {
  visibility: visible;
  opacity: 1;
  z-index: 10000000;
}

/*# sourceMappingURL=globalLoader.css.map */

.onboarding form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.onboarding h3,
.onboarding h4,
.onboarding p {
  text-align: center;
}
.onboarding h3 {
  max-width: 280px;
  font-size: 23px;
  font-weight: 500;
}
.onboarding p {
  font-size: 14px;
  margin-top: 0;
}
.onboarding .goBack {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.onboarding .goBack svg {
  width: 25px;
  height: 25px;
}
.onboarding .input-control {
  flex: unset;
  width: 100%;
  max-width: 300px;
}
.onboarding .input-control .input-field {
  border-radius: 0;
  height: 55px;
}
.onboarding .input-control input {
  width: 100%;
  text-align: center;
}
.onboarding button {
  font-size: 16px;
  height: 45px;
  width: 140px;
  font-weight: bold;
}

/*# sourceMappingURL=onboarding.css.map */

.input-control {
  position: relative;
  flex: 1 1;
}
.input-control > * {
  font-family: "Nunito Sans", sans-serif;
}
.input-control .input-container-cover {
  display: flex;
  width: 100%;
}
.input-control .input-error-text {
  margin-top: 5px;
  color: #ff652d;
  font-size: 13px;
}
.input-control .currency-selector {
  width: 90px;
}
.input-control .currency-selector .select-control {
  min-width: 80px;
}
.input-control .currency-selector .select-control .select-button {
  border: none;
  border-right: 1px solid #cccccc;
}
.input-control .dropdown-control {
  height: 50px;
  width: 120px;
  margin-right: 10px;
}
.input-control .dropdown-control input {
  text-align: center;
}
.input-control .phone-dropdown {
  padding: 0 3px !important;
}
.input-control .input-field {
  width: 100%;
  height: 40px;
  border: 2px solid transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  transition: 0.5s ease;
  min-width: 100px;
  background-color: white !important;
  margin-bottom: 5px;
}
.input-control .input-field i:first-child {
  display: block;
  padding-left: 10px;
}
.input-control .input-field i:last-child {
  display: block;
  padding-right: 10px;
}
.input-control .input-field:hover, .input-control .input-field:focus-within {
  border-color: #bee2f3;
}
.input-control input {
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  outline: none;
  padding: 0 20px;
  color: black !important;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.input-control input.iconLeft {
  padding-left: 30px;
}
.input-control input.iconRight {
  padding-right: 40px;
}
.input-control input:disabled {
  cursor: not-allowed;
}
.input-control input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}
.input-control .iconLeft {
  position: absolute;
  left: 0;
  color: #999999;
}
.input-control .iconRight {
  position: absolute;
  right: 0;
  color: #999999;
}
.input-control .input-field.disabled:hover,
.input-control .input-field.disabled:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
.input-control .input-field.disabled input {
  color: #999999 !important;
}

.input-control.error .input-field {
  border-color: #ff652d;
}
.input-control.error .input-field input {
  color: #ff652d;
}

.input-control.small .input-field {
  height: 25px;
}
.input-control.small .input-field input {
  font-size: 12px;
}

.input-control.large .input-field {
  height: 55px;
}
.input-control.large .input-field input {
  font-size: 18px;
}

/*# sourceMappingURL=Input.css.map */

.btn-main {
  background: blue;
}

.btn {
  font-family: "Nunito Sans", sans-serif;
  background: transparent;
  border-radius: 4px;
  padding: 0 15px;
  height: 35px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center !important;
  font-size: 12px;
  justify-content: center !important;
  border: 1px solid transparent;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn:focus,
.btn:active,
.btn-sml:focus,
.btn-sml:active,
.btn {
  outline: none;
  position: relative;
  text-decoration: none;
  box-shadow: -3px 21px 42px -38px #5680f9;
}
.btn:focus.primary,
.btn:active.primary,
.btn-sml:focus.primary,
.btn-sml:active.primary,
.btn.primary {
  background: #3da7db;
  transition: 0.3s ease;
}
.btn:focus.primary:hover,
.btn:active.primary:hover,
.btn-sml:focus.primary:hover,
.btn-sml:active.primary:hover,
.btn.primary:hover {
  background: #248dc1;
}
.btn:focus.danger,
.btn:active.danger,
.btn-sml:focus.danger,
.btn-sml:active.danger,
.btn.danger {
  background: #ff652d;
  transition: 0.3s ease;
}
.btn:focus.danger:hover,
.btn:active.danger:hover,
.btn-sml:focus.danger:hover,
.btn-sml:active.danger:hover,
.btn.danger:hover {
  background: #f94200;
}
.btn:focus.success,
.btn:active.success,
.btn-sml:focus.success,
.btn-sml:active.success,
.btn.success {
  background: #3dd89a;
  transition: 0.3s ease;
}
.btn:focus.success:hover,
.btn:active.success:hover,
.btn-sml:focus.success:hover,
.btn-sml:active.success:hover,
.btn.success:hover {
  background: #26bc80;
}
.btn:focus.default,
.btn:active.default,
.btn-sml:focus.default,
.btn-sml:active.default,
.btn.default {
  background: #ffffff;
  color: #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
}
.btn:focus.default:hover,
.btn:active.default:hover,
.btn-sml:focus.default:hover,
.btn-sml:active.default:hover,
.btn.default:hover {
  background: #ccd6e1;
}
.btn:focus.loading,
.btn:active.loading,
.btn-sml:focus.loading,
.btn-sml:active.loading,
.btn.loading {
  opacity: 0.7;
}
.btn:focus.loading > span,
.btn:active.loading > span,
.btn-sml:focus.loading > span,
.btn-sml:active.loading > span,
.btn.loading > span {
  margin-right: 6px;
}
.btn:focus:hover,
.btn:active:hover,
.btn-sml:focus:hover,
.btn-sml:active:hover,
.btn:hover {
  cursor: pointer;
}
.btn:focus:disabled,
.btn:focus .disabled,
.btn:active:disabled,
.btn:active .disabled,
.btn-sml:focus:disabled,
.btn-sml:focus .disabled,
.btn-sml:active:disabled,
.btn-sml:active .disabled,
.btn:disabled,
.btn .disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.btn:focus.block,
.btn:active.block,
.btn-sml:focus.block,
.btn-sml:active.block,
.btn.block {
  width: 100%;
  max-width: 300px;
  display: block;
}
.btn:focus .icon-left,
.btn:active .icon-left,
.btn-sml:focus .icon-left,
.btn-sml:active .icon-left,
.btn .icon-left {
  padding-right: 10px;
}
.btn:focus .content,
.btn:active .content,
.btn-sml:focus .content,
.btn-sml:active .content,
.btn .content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.spinner.danger .lds-dual-ring {
  color: #ff652d !important;
}

/*# sourceMappingURL=Button.css.map */

.modalCustom {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
}
.modalCustom.show {
  z-index: 1000000;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}
.modalCustom .close {
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
}
.modalCustom .close svg {
  width: 30px;
  height: 30px;
}
.modalCustom .modalCustom-content {
  position: relative;
  color: white;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
  box-sizing: border-box;
  min-height: 600px;
}

/*# sourceMappingURL=modalCustom.css.map */

.notification-main {
  width: 100%;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
  margin-bottom: 20px;
  z-index: 100000001;
}
.notification-main .close-button {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  cursor: pointer;
}
.notification-main .contents {
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 600;
  color: white;
}
.notification-main .contents .title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notification-main.success {
  background-color: #67e1b0;
}

.notification-main.info {
  background-color: #425cb9;
}

.notification-main.warning {
  background-color: #ebd18e;
}

.notification-main.error {
  background-color: #ff652d;
}

.notification-main.default {
  color: black;
}

.notification-main.close {
  visibility: hidden;
  opacity: 0;
}

.notification-main.remove {
  display: none !important;
}

#notification-root {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000001 !important;
}

.notification-bubble {
  width: 500px;
  max-width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: 0.3s ease;
  -webkit-animation: animateNotificationBubble 0.3s ease-in-out forwards;
          animation: animateNotificationBubble 0.3s ease-in-out forwards;
}
@media screen and (max-width: 480px) {
  .notification-bubble {
    width: unset;
  }
}

@-webkit-keyframes animateNotificationBubble {
  to {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@keyframes animateNotificationBubble {
  to {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/*# sourceMappingURL=Notification.css.map */

.dashboard {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  min-height: 70vh;
}
@media screen and (max-width: 768px) {
  .dashboard {
    margin-top: 10px;
  }
}

.dashboard-inner,
.dashboard-inner2 {
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
  padding-right: 3em;
  box-sizing: border-box;
}
.dashboard-inner .small,
.dashboard-inner2 .small {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .dashboard-inner,
.dashboard-inner2 {
    padding-right: 0;
  }
}
.dashboard-inner .game-head,
.dashboard-inner2 .game-head {
  background-color: black;
  padding: 0 20px;
}
.dashboard-inner .heading-dash,
.dashboard-inner2 .heading-dash {
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .dashboard-inner .heading-dash,
.dashboard-inner2 .heading-dash {
    justify-content: center;
  }
}
.dashboard-inner .heading-dash .img-con,
.dashboard-inner2 .heading-dash .img-con {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
}
.dashboard-inner .heading-dash .img-con img,
.dashboard-inner2 .heading-dash .img-con img {
  width: 45px;
}
.dashboard-inner .heading-dash span,
.dashboard-inner2 .heading-dash span {
  font-weight: bold;
  color: #3da7db;
}
.dashboard-inner .heading-dash h3,
.dashboard-inner2 .heading-dash h3 {
  text-align: center;
  font-weight: 400;
  font-size: 17px;
}
@media screen and (max-width: 768px) {
  .dashboard-inner .heading-dash h3,
.dashboard-inner2 .heading-dash h3 {
    font-size: 13px;
    margin: unset;
    text-align: left;
    max-width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .dashboard-inner .heading-dash.game-main h3,
.dashboard-inner2 .heading-dash.game-main h3 {
    text-align: center;
  }
}
.dashboard-inner .dasboard-grid,
.dashboard-inner .grid,
.dashboard-inner2 .dasboard-grid,
.dashboard-inner2 .grid {
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
}
@media screen and (max-width: 768px) {
  .dashboard-inner .dasboard-grid,
.dashboard-inner .grid,
.dashboard-inner2 .dasboard-grid,
.dashboard-inner2 .grid {
    grid-gap: 15px;
  }
}
@media screen and (max-width: 600px) {
  .dashboard-inner .dasboard-grid,
.dashboard-inner2 .dasboard-grid {
    grid-template-columns: 1fr;
  }
}
.dashboard-inner .grid-dash,
.dashboard-inner2 .grid-dash {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid gray;
  border-left: 8px solid red;
  color: white;
  min-height: 150px;
}
.dashboard-inner .grid-dash .currency,
.dashboard-inner2 .grid-dash .currency {
  height: 30px;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.dashboard-inner .grid-dash .grid,
.dashboard-inner2 .grid-dash .grid {
  grid-gap: 1em;
}
.dashboard-inner .grid-dash h5,
.dashboard-inner .grid-dash h3,
.dashboard-inner .grid-dash h4,
.dashboard-inner2 .grid-dash h5,
.dashboard-inner2 .grid-dash h3,
.dashboard-inner2 .grid-dash h4 {
  margin: 0;
}
.dashboard-inner .grid-dash h5,
.dashboard-inner2 .grid-dash h5 {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.dashboard-inner .grid-dash small,
.dashboard-inner2 .grid-dash small {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
}
@media screen and (max-width: 768px) {
  .dashboard-inner .grid-dash,
.dashboard-inner2 .grid-dash {
    width: unset;
    border-left-width: 2px;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .dashboard-inner .grid-dash small,
.dashboard-inner2 .grid-dash small {
    font-size: 8px;
    line-height: 10px !important;
    display: block;
  }
  .dashboard-inner .grid-dash h5,
.dashboard-inner2 .grid-dash h5 {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .dashboard-inner .grid-dash h3,
.dashboard-inner2 .grid-dash h3 {
    font-size: 13px;
  }
  .dashboard-inner .grid-dash .grid,
.dashboard-inner2 .grid-dash .grid {
    margin-top: 10px;
  }
  .dashboard-inner .grid-dash .currency,
.dashboard-inner2 .grid-dash .currency {
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }
}
.dashboard-inner .grid-dash.account-balance,
.dashboard-inner2 .grid-dash.account-balance {
  background-color: green;
  border-color: green;
}
.dashboard-inner .grid-dash.raffle,
.dashboard-inner2 .grid-dash.raffle {
  border-left-color: #248dc1;
}
.dashboard-inner .grid-dash.raffle h5,
.dashboard-inner .grid-dash.raffle .currency,
.dashboard-inner2 .grid-dash.raffle h5,
.dashboard-inner2 .grid-dash.raffle .currency {
  color: #248dc1;
}
.dashboard-inner .grid-dash.transaction,
.dashboard-inner2 .grid-dash.transaction {
  border-left-color: red;
}
.dashboard-inner .grid-dash.transaction h5,
.dashboard-inner .grid-dash.transaction .currency,
.dashboard-inner2 .grid-dash.transaction h5,
.dashboard-inner2 .grid-dash.transaction .currency {
  color: red;
}
.dashboard-inner .grid-dash.game-history,
.dashboard-inner2 .grid-dash.game-history {
  border-left-color: yellow;
}
.dashboard-inner .grid-dash.game-history h5,
.dashboard-inner .grid-dash.game-history .currency,
.dashboard-inner2 .grid-dash.game-history h5,
.dashboard-inner2 .grid-dash.game-history .currency {
  color: yellow;
}

.dashboard-inner2 {
  max-width: 800px !important;
  flex: 1 1;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .dashboard-inner2 {
    margin-top: 0;
  }
}

.side {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.side h4 {
  text-align: right;
  color: white;
  max-width: 180px;
  font-weight: 500;
  font-size: 14px;
}
.side .img-con {
  height: 250px;
  max-width: 180px;
  width: 100%;
  background-image: url(/static/media/support.8a6ca7cd.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px;
}
.side button {
  display: block;
  margin-top: 15px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  color: white;
  padding: 15px 0;
  max-width: 180px;
}

.below {
  max-width: 200px;
  text-align: right;
  color: #3da7db;
  font-size: 11px;
}
.below .top {
  font-weight: 500;
  color: white;
}
.below .top .win {
  color: green;
}
.below .top .lose {
  color: red;
}
.below .grand {
  font-size: 28px;
  color: white;
  font-weight: 600;
}
@media screen and (max-width: 420px) {
  .below {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 360px) {
  .below {
    transform: scale(0.6);
  }
}
@media screen and (max-width: 768px) {
  .below {
    text-align: center;
    margin: 0 auto;
    margin-top: 55%;
  }
}
@media screen and (max-width: 710px) {
  .below {
    margin-top: 60%;
  }
}
@media screen and (max-width: 650px) {
  .below {
    margin-top: 70%;
  }
}
@media screen and (max-width: 600px) {
  .below {
    margin-top: 30vh;
  }
}
@media screen and (max-width: 380px) {
  .below {
    margin-top: 25vh;
  }
}
@media screen and (max-width: 320px) {
  .below {
    margin-top: 20vh;
  }
}
@media screen and (max-width: 600px) and (max-height: 812px) {
  .below {
    margin-top: 30vh;
  }
}
@media screen and (max-width: 600px) and (max-height: 750px) {
  .below {
    margin-top: 35vh;
  }
}
@media screen and (max-width: 600px) and (max-height: 580px) {
  .below {
    margin-top: 32vh;
  }
}

center small {
  color: white !important;
  margin-bottom: 15px;
  display: block;
}

.inner-game-control {
  height: 100%;
  position: relative;
  z-index: 10;
}

.filter {
  font-size: 13px;
  color: #b3b3b3;
  display: flex;
}
.filter span {
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.play-main {
  width: 100%;
  max-width: 200px;
  min-width: 200px;
}

.btn-mobile {
  padding: 15px 30px;
  font-size: 13px;
  text-transform: capitalize;
  color: white;
  font-weight: bold;
}

.trans-table {
  margin-top: 20px;
  margin-right: 0;
  padding-right: 0;
}
.trans-table table {
  border: 1px solid white;
  box-sizing: border-box;
  padding: 5px 0;
  border-collapse: collapse;
  min-width: 100%;
}
.trans-table .t-content {
  overflow: auto;
}
.trans-table tr {
  border-bottom: 1px solid white;
}
.trans-table th,
.trans-table td {
  border-right: 1px solid white;
}
.trans-table td,
.trans-table th {
  color: white;
  text-align: left;
}
.trans-table td.debit,
.trans-table th.debit {
  color: red;
}
.trans-table td.credit,
.trans-table th.credit {
  color: green;
}
.trans-table td .status,
.trans-table th .status {
  font-size: 10px;
  background-color: gray;
  color: white;
  width: 100px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.trans-table td .status.lose,
.trans-table th .status.lose {
  background-color: red;
}
.trans-table td .status.win,
.trans-table th .status.win {
  background-color: green;
}
.trans-table td .status.pending,
.trans-table th .status.pending {
  background-color: #2956b4;
}
.trans-table td,
.trans-table th {
  font-size: 12px;
  padding: 10px 20px;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .trans-table td,
.trans-table th {
    font-size: 9px;
    padding: 10px;
  }
  .trans-table td .status,
.trans-table th .status {
    font-size: 7px;
    padding: 0 5px;
    height: 15px;
    line-height: 15px;
    max-width: 60px;
  }
}
.trans-table th {
  font-weight: 500;
}

select,
input {
  min-width: 140px;
  font-size: 11px;
  padding: 10px;
  box-sizing: border-box;
}

input {
  background-color: transparent;
  border: 1px solid white;
  outline: none;
  color: white;
}

.input-check {
  position: relative;
}
.input-check .open,
.input-check .close {
  position: absolute;
  top: -4px;
  left: 0;
}
.input-check .open i,
.input-check .close i {
  font-size: 30px;
  color: #248dc1;
}
.input-check .close {
  display: none;
}
.input-check .close i {
  color: white;
}

input[type=checkbox] {
  min-width: unset;
  visibility: hidden;
  position: relative;
}
input[type=checkbox]:checked ~ .open {
  display: none;
}
input[type=checkbox]:checked ~ .close {
  display: block;
}

/*# sourceMappingURL=dashboard.css.map */

.range-filter {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
}

.range-filter input {
  margin-right: 10px;
  background-color: white;
  color: black;
  height: 35px;
  width: 200px;
}

.range-filter label small {
  color: white;
  display: block;
  font-size: 10px;
  margin-bottom: 5px;
}
/*# sourceMappingURL=rangeFilter.css.map */
.modalCustom {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
}
.modalCustom.show {
  z-index: 1000000;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}
.modalCustom .close {
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
}
.modalCustom .close svg {
  width: 30px;
  height: 30px;
}
.modalCustom .modalCustom-content {
  position: relative;
  color: white;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
  box-sizing: border-box;
  min-height: 600px;
}

/*# sourceMappingURL=modalCustom.css.map */

.blackfriday {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 1000px;
  background-image: linear-gradient(140deg, #060606, #2a2426);
  padding: 40px;
  box-sizing: border-box;
}
.blackfriday * {
  font-family: "Poppins", sans-serif;
}
.blackfriday img {
  width: 100px;
}
@media screen and (max-width: 550px) {
  .blackfriday {
    margin: 0 20px;
    max-width: 400px;
    background-image: radial-gradient(#060606, #2a2426, #2a2426);
    background-size: cover;
    background-position: center;
  }
}
.blackfriday .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.blackfriday .head button {
  background-color: white;
  color: #000;
  border-radius: 0;
  font-size: 25px;
  font-weight: 600;
  width: unset;
  height: unset;
  padding: 5px 15px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .blackfriday .head {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }
  .blackfriday .head button {
    padding: 5px 12px;
    font-size: 18px;
  }
}
.blackfriday .contentMain {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
  position: relative;
}
.blackfriday .blackBallons {
  position: absolute;
  left: -10vh;
  top: 50%;
  transform: translateY(calc(-50% + 50px));
  width: 100%;
}
@media screen and (max-width: 550px) {
  .blackfriday .blackBallons {
    bottom: 0;
    top: unset;
    transform: unset;
    left: -5vh;
    width: 140%;
  }
}
.blackfriday .blackLogo {
  width: 15vh;
}
@media screen and (max-width: 550px) {
  .blackfriday .blackLogo {
    margin-top: 5vh;
  }
}
.blackfriday .textFormation {
  display: flex;
  margin-top: 3vh;
  color: white;
  align-items: center;
}
.blackfriday .textFormation .main {
  font-size: 5rem;
  margin-right: 10px;
  font-family: "Bebas Neue", cursive !important;
}
.blackfriday .textFormation .others {
  font-size: 2.2rem;
  line-height: 30px;
}
.blackfriday .textFormation .others * {
  font-family: "Bebas Neue", cursive !important;
}
@media screen and (max-width: 550px) {
  .blackfriday .textFormation .main {
    font-size: 4rem;
    margin-right: 5px;
  }
  .blackfriday .textFormation .others {
    font-size: 1.8rem;
    line-height: 25px;
  }
}
.blackfriday .blackCash {
  width: 40vh;
}
@media screen and (max-width: 550px) {
  .blackfriday .blackCash {
    width: 37vh;
  }
}
.blackfriday .context {
  margin: 5px 0;
  margin-bottom: 10px;
  color: white;
  font-family: "Bebas Neue", cursive !important;
  font-size: 2.6rem;
}
@media screen and (max-width: 550px) {
  .blackfriday .context {
    font-size: 1.8rem;
  }
}
.blackfriday .textBox {
  color: white;
  font-size: 1.2rem;
  padding: 0 3vh;
  font-weight: 600;
  background: linear-gradient(90deg, #b68135, #b68135, #fcd35f, #fcd35f, #cfc8b6, #c7903d, #c7903d, #c7903d, #c7903d, #6d4112, #c7903d, #c7903d);
}
.blackfriday .playButt {
  border-radius: 50px;
  color: #ec424b;
  background: white;
  margin-top: 5vh;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 5vh;
}
@media screen and (max-width: 550px) {
  .blackfriday .playButt {
    margin-top: 15vh;
    width: 100%;
    font-weight: 500;
  }
}
.blackfriday .tc {
  color: white;
  margin-top: 3vh;
  font-size: 14px;
  font-weight: 500;
}
@media screen and (max-width: 550px) {
  .blackfriday .tc {
    margin-bottom: 20px;
  }
}
.blackfriday .logo {
  width: 70px;
}

/*# sourceMappingURL=blackFriday.css.map */

.countDown {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  padding: 20px 40px;
  box-sizing: border-box;
}
.countDown * {
  font-family: "Poppins", sans-serif;
}
.countDown::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/countCover.24cdaa5c.png) !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.countDown img {
  width: 100px;
}
@media screen and (max-width: 550px) {
  .countDown {
    margin: 0 20px;
    max-width: 400px;
  }
  .countDown::before {
    background-image: url(/static/media/countCoverV.063225c2.png) !important;
    background-size: 150%;
  }
}
.countDown .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.countDown .head button {
  background-color: white;
  color: #000;
  border-radius: 0;
  font-size: 25px;
  font-weight: 600;
  width: unset;
  height: unset;
  padding: 5px 15px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .countDown .head {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }
  .countDown .head button {
    padding: 5px 12px;
    font-size: 18px;
  }
}
.countDown .contentMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 4vh;
}
@media screen and (max-width: 550px) {
  .countDown .contentMain {
    margin-top: 5vh;
  }
}
.countDown .context {
  margin: 20px 0;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}
@media screen and (max-width: 550px) {
  .countDown .context {
    font-size: 1rem;
    margin-bottom: 15px;
  }
}
.countDown .textBox {
  color: black;
  font-size: 0.9rem;
  padding: 3px 3vh;
  font-weight: 500;
  background-color: #f3b53e;
}
.countDown .timer {
  color: white;
  font-family: "DigitalFont", cursive !important;
  line-height: 3rem;
  font-style: italic;
  font-size: 6.5rem;
}
@media screen and (max-width: 550px) {
  .countDown .timer {
    font-size: 5.5rem;
  }
}
.countDown .winners {
  color: white;
  font-family: "DhanFont", cursive !important;
  font-size: 2rem;
  margin: 10px 0;
  margin-top: 5vh;
}
@media screen and (max-width: 550px) {
  .countDown .winners {
    font-size: 1.3rem;
    margin-top: 2vh;
  }
}
.countDown .textContent {
  margin-top: 5vh;
  margin-bottom: 5px;
  font-size: 20px;
  color: white;
}
@media screen and (max-width: 550px) {
  .countDown .textContent {
    font-size: 1rem;
    font-weight: 600;
  }
}
.countDown .playButt {
  border-radius: 50px;
  color: #ec424b;
  background: white;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 5vh;
}
@media screen and (max-width: 550px) {
  .countDown .playButt {
    width: 100%;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
.countDown .ribbon {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
}
.countDown .price {
  position: absolute;
  z-index: 1;
  width: 25vh;
  left: 0;
  bottom: 0;
}
@media screen and (max-width: 550px) {
  .countDown .price {
    position: relative;
    width: 22vh;
  }
}
.countDown .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
}
.countDown .logo {
  width: 70px;
}

/*# sourceMappingURL=countDown.css.map */

.daysOff {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 20px 40px;
}
.daysOff * {
  font-family: "Poppins", sans-serif;
}
.daysOff img {
  width: 100px;
}
.daysOff::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/daysCover.f6ce1f23.jpg) !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 550px) {
  .daysOff {
    margin: 0 20px;
    max-width: 400px;
  }
  .daysOff::before {
    background-image: url(/static/media/daysCoverV.6a719f70.jpg) !important;
  }
}
.daysOff .head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  padding: 20px;
}
.daysOff .head button {
  background-color: white;
  color: #000;
  border-radius: 0;
  font-size: 18px;
  font-weight: 600;
  width: unset;
  height: unset;
  padding: 5px 12px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .daysOff .head {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.daysOff .ribbon {
  background-color: white;
  padding: 10px 20px;
  width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 550px) {
  .daysOff .ribbon {
    position: relative;
    margin-top: 10vh;
    width: 100px;
  }
}
.daysOff .contentMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 30px;
}
@media screen and (max-width: 550px) {
  .daysOff .contentMain {
    margin-top: 0;
  }
}
.daysOff .containerMain {
  position: relative;
}
.daysOff .context {
  color: white;
  font-size: 6rem;
  font-weight: 500;
  font-family: "Bebas Neue", cursive;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 550px) {
  .daysOff .context {
    font-size: 4rem;
  }
}
.daysOff .basket {
  color: white;
  font-family: "DhanFont", cursive !important;
  font-size: 3rem;
  right: -50px;
  top: 5.1rem;
  position: absolute;
}
@media screen and (max-width: 550px) {
  .daysOff .basket {
    font-size: 2rem;
    right: 0;
    top: 4rem;
  }
}
.daysOff .textBox {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 30px;
  text-align: center;
  font-weight: 300;
}
@media screen and (max-width: 550px) {
  .daysOff .textBox {
    margin-top: 8vh;
    font-size: 0.9rem;
    font-weight: 400;
  }
}
.daysOff .textContent {
  margin-top: 6vh;
  margin-bottom: 5px;
  font-size: 20px;
  color: white;
}
@media screen and (max-width: 550px) {
  .daysOff .textContent {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
.daysOff .playButt {
  border-radius: 50px;
  color: #ec424b;
  background: white;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 5vh;
  margin-bottom: 50px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.daysOff .logo {
  width: 70px;
}

/*# sourceMappingURL=daysOf.css.map */

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", serif;
  background-color: #04000a;
}

a {
  text-decoration: none;
}

.mobile {
  display: none !important;
}
.mobile .menu-bar {
  color: white;
}
.mobile .menu-bar svg {
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 768px) {
  .mobile {
    display: block !important;
  }
}

.pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
}
.pager button {
  padding: 0;
  height: 30px;
  width: 30px;
  margin-left: 10px;
}
.pager button.disabled {
  background-color: rgba(255, 255, 255, 0.4);
}

.modal-fixed .modalCustom-content {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: black !important;
  overflow: auto !important;
  max-height: 400px;
}
.modal-fixed .modalCustom-content h3 {
  font-size: 18px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .modal-fixed .modalCustom-content h3 {
    font-size: 15px;
  }
}
.modal-fixed .modalCustom-content-2 {
  background-color: black !important;
  overflow: auto !important;
  max-height: 400px;
  width: 100%;
}
.modal-fixed .modalCustom-content-2 h3 {
  font-size: 18px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .modal-fixed .modalCustom-content-2 h3 {
    font-size: 15px;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.filters {
  margin-right: 10px;
  flex-wrap: wrap;
}
.filters .select-input {
  min-width: 200px;
}
.filters .input-control .input-field {
  height: 35px;
  border-radius: 0;
}
.filters .input-control input {
  font-size: 11px;
}
@media screen and (max-width: 600px) {
  .filters .select-input {
    min-width: 100px;
    margin-left: 10px;
  }
  .filters .input-control input {
    padding: 0;
  }
}

.raffleConfirmation .context {
  font-size: 15px;
  text-align: center;
  margin-bottom: 5px;
}

.info {
  font-size: 12px;
  color: #999999;
}

@media screen and (max-width: 600px) {
  .info {
    font-size: 10px;
  }

  .context {
    font-size: 12px;
  }
}
.grid-main {
  display: grid;
  grid-gap: 2em;
}
.grid-main.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.payment-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1em;
  width: 100%;
}
.payment-card-grid .card-item {
  padding: 10px;
  border: 1px solid #4d4d4d;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s ease;
}
.payment-card-grid .card-item:hover {
  border-color: #3da7db;
}
.payment-card-grid .card-item .title {
  font-size: 13px;
  color: #b3b3b3;
}
.payment-card-grid .card-item .cardType {
  font-size: 12px;
}
.payment-card-grid .add-card {
  background-color: black;
  display: flex;
  max-width: 100px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(61, 167, 219, 0.5);
  cursor: pointer;
  min-height: 80px;
}
.payment-card-grid .add-card svg {
  width: 30px;
  height: 30px;
  color: #3da7db;
}

.full-width {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none !important;
  }
}

.redirection {
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.95);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100000000;
}
.redirection h3 {
  font-style: italic;
  color: navajowhite;
  font-size: 14px;
}
.redirection p {
  margin: 0;
  color: white;
}
.redirection a {
  color: #a998ff;
  cursor: pointer;
}

.bg-container {
  overflow: hidden;
  padding: 0 40px;
  box-sizing: border-box;
}

.inner-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inner-container .children {
  height: 100%;
  min-height: 650px;
}
@media screen and (max-width: 600px) {
  .inner-container .children {
    min-height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .inner-container {
    display: block;
  }
}

.heading {
  display: flex;
  align-items: center;
  height: 120px;
  position: relative;
  z-index: 2;
}
.heading .brand {
  color: white;
}
.heading .brand img {
  width: 60px;
  margin-top: 20px;
}
.heading .desktop {
  width: 100%;
}
.heading .desktop .container-right {
  justify-content: flex-end;
  display: flex;
  flex: 1 1;
}
.heading .navright {
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
}
.heading .navright img {
  width: 40px;
}
.heading .navright a {
  margin-right: 20px;
  color: white;
}
.heading .navright a:last-child {
  margin-right: 0;
}
.heading .navright li {
  color: white;
  padding: 5px 20px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.heading .navright li .dropdown {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  min-width: 150px;
  background-color: #1b0c2c;
  padding: 10px;
  border-radius: 4px;
  display: none;
}
.heading .navright li .dropdown a {
  text-decoration: none;
  margin-right: 0;
}
.heading .navright li .dropdown .drop-item {
  font-size: 13px;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
}
.heading .navright li .dropdown .drop-item:hover {
  color: #3da7db;
}
.heading .navright li:hover .dropdown {
  display: block;
}
.heading .navright li .profile {
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.heading .navright li .profile img {
  width: 20px;
}
.heading .navright li.signin {
  background-color: #ea3225;
  font-size: 12px;
  padding: 5px 25px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
.heading .navright li.register {
  background-color: #eba453;
  font-size: 12px;
  padding: 5px 25px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 7px;
  color: black !important;
}
.heading .navright li.register:hover {
  color: black !important;
}
.heading .navright li.bal {
  background-color: #3da7db;
  flex-direction: column;
  font-size: 13px;
  border-radius: 6px;
}
.heading .navright li.bal .inner {
  display: flex;
  align-items: center;
}
.heading .navright li.bal .money {
  font-weight: 600;
  font-size: 17px;
  font-family: "Nunito Sans", sans-serif;
}
.heading .navright li.bal img {
  width: 20px;
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
  .heading .navright.mobile {
    display: flex !important;
    justify-content: flex-end;
  }
}

button {
  background-color: #3da7db;
  border: none;
  outline: none;
  padding: 10px 30px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

h4 {
  color: white;
}

.main-content {
  margin: auto 0;
  height: 100%;
}
.main-content h2 {
  font-size: 35px;
  color: white;
  max-width: 350px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 35px;
}
.main-content p {
  font-size: 13px;
  color: white;
  max-width: 500px;
  font-weight: 400;
}
.main-content button {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  padding: 12px 30px !important;
}
@media screen and (max-width: 768px) {
  .main-content {
    min-height: unset;
    margin: 0;
  }
  .main-content h2 {
    font-size: 30px;
    max-width: 250px;
  }
  .main-content p {
    max-width: 300px;
  }
  .main-content button {
    font-size: 13px;
  }
}

.chat {
  background-color: #3da7db;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}
.chat.fixed {
  position: fixed;
  z-index: 20;
  right: 40px;
  bottom: 40px;
}

.bottom-items-container {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-items-container .bottom-items {
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}
.bottom-items-container .powered {
  font-size: 9px;
  text-align: center;
}
.bottom-items-container .powered img {
  width: 150px;
}

.input-field-v,
.input-field-v1 {
  position: relative;
  margin-right: 10px;
}
.input-field-v:last-child,
.input-field-v1:last-child {
  margin-right: 0;
}
.input-field-v.selected,
.input-field-v1.selected {
  position: relative;
}
.input-field-v.selected:after,
.input-field-v1.selected:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  z-index: 2;
  box-sizing: border-box;
  -webkit-animation: CheckSelected ease-in-out 500ms forwards;
          animation: CheckSelected ease-in-out 500ms forwards;
}

@-webkit-keyframes CheckSelected {
  0% {
    transform-origin: center;
    transform: scale(2);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    border-color: #10d098;
    visibility: visible;
  }
}

@keyframes CheckSelected {
  0% {
    transform-origin: center;
    transform: scale(2);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    border-color: #10d098;
    visibility: visible;
  }
}
.loader-main {
  position: fixed;
  background-color: #080011;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden !important;
  display: flex;
  left: 0;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 40;
}
.loader-main .counter {
  margin-top: 40px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.modal-main {
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 768px;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
  visibility: hidden;
  opacity: 0;
}
@media screen and (max-width: 600px) {
  .modal-main {
    align-items: flex-start;
  }
}
.modal-main .inner {
  width: 100%;
  max-width: 800px;
  min-height: 60vh;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
}
@media screen and (max-width: 600px) {
  .modal-main .inner {
    min-height: 70vh;
    margin-top: 15vh;
  }
}
.modal-main .inner .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.modal-main .inner .content .close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
}
@media screen and (max-width: 600px) {
  .modal-main .inner .content .close {
    top: 10px;
    right: 10px;
  }
}
.modal-main .inner .content .heading {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
}
.modal-main .inner .content p {
  margin-top: 0;
  font-size: 14px;
}
.modal-main .inner .content .input-control {
  border: none;
  background-color: white;
  width: 100%;
  height: 60px;
  font-size: 24px;
  color: black;
  max-width: 300px;
  border-radius: 2px;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
}
.modal-main .inner .content button {
  margin-top: 15px;
  padding: 15px 35px;
  color: white;
  font-size: 17px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .modal-main .inner .content .heading {
    font-size: 17px;
  }
  .modal-main .inner .content p {
    text-align: center;
    font-size: 11px;
  }
  .modal-main .inner .content .input-control {
    height: 50px;
    margin-top: 20px;
    max-width: 250px;
    font-size: 17px;
  }
  .modal-main .inner .content button {
    padding: 15px 25px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .modal-main .inner {
    max-width: 80vw;
  }
}
.modal-main#modalMain:target {
  visibility: visible;
  opacity: 1;
}
.modal-main#modalMain2:target {
  visibility: visible;
  opacity: 1;
}
.modal-main#modalMain3:target {
  visibility: visible;
  opacity: 1;
}
.modal-main#modalMain4:target {
  visibility: visible;
  opacity: 1;
}
.modal-main#winModal:target {
  visibility: visible;
  opacity: 1;
}
.modal-main#lossModal:target {
  visibility: visible;
  opacity: 1;
}

.ball-show {
  display: flex;
  min-width: 250px;
  align-items: center;
  justify-content: space-between;
}
.ball-show .label-data {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
.ball-show .balls-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ball-show .ballVal {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  border-radius: 50%;
  margin-right: 10px;
  border: 4px solid #e6e6e6;
  box-sizing: border-box;
}
.ball-show .ballVal:last-child {
  margin-right: 0;
}
.ball-show .ballVal.selected {
  border-color: #06a800;
}

.won-main {
  color: white;
  margin: 0 auto;
  text-align: center;
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.won-main .won {
  font-size: 35px;
  font-weight: 600;
  color: #3da7db;
}
.won-main .won.not {
  color: red;
}
@media screen and (max-width: 480px) {
  .won-main .won {
    font-size: 25px;
  }
}
.won-main h2 {
  font-weight: bolder;
  font-size: 50px;
  max-width: 100%;
  margin: 0;
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .won-main h2 {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 768px) {
  .won-main .won {
    font-size: 27px;
    margin-bottom: 0;
    margin-top: 30px;
    font-weight: bold;
  }
  .won-main h2 {
    font-size: 30px;
    margin-top: 0;
  }
  .won-main p {
    max-width: 100%;
  }
  .won-main .controls button {
    margin-right: 0;
    font-size: 15px;
    border-radius: 10px;
    padding: 15px 30px;
  }
  .won-main .controls button.outline {
    margin-right: 0;
    background-color: transparent;
    border: none;
  }
}
@media screen and (max-width: 480px) {
  .won-main .won {
    font-size: 23px;
    margin-top: 0;
  }
  .won-main .controls {
    transform: scale(0.8);
  }
}

.controls button {
  font-size: 20px;
  width: 230px;
  height: 60px;
  margin-right: 30px;
}
.controls button.outline {
  margin-right: 0;
  background-color: transparent;
  border: 2px solid #3da7db;
}
@media screen and (max-width: 480px) {
  .controls button {
    font-size: 13px !important;
    width: 140px !important;
    height: 50px;
  }
}
@media screen and (max-width: 374px) {
  .controls button {
    font-size: 10px !important;
    width: 120px !important;
    height: 50px;
    padding: 0;
  }
}

.social-link {
  display: flex;
  width: 100%;
  max-width: 180px;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  color: white;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 20px;
}
.social-link img {
  width: 18px;
}
.social-link .facebook img {
  width: 10px;
}
@media screen and (max-width: 768px) {
  .social-link {
    max-width: 120px;
  }
  .social-link img {
    width: 14px;
  }
  .social-link .facebook img {
    width: 6px;
  }
}

.sharer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.sharer p {
  font-size: 12px;
  margin-bottom: 5px;
}
.sharer button {
  color: white;
  font-weight: bold;
}

.center-mode {
  margin: 0 auto;
  text-align: center;
}
.center-mode p {
  font-size: 22px;
}
.center-mode .fund-list {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-mode .fund-list .fund-item {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  cursor: pointer;
}
.center-mode .fund-list .fund-item img {
  width: 70px;
}
.center-mode .fund-list .fund-item:last-child {
  margin-right: 0;
}
.center-mode .transtart {
  background-color: #a82b2b;
  height: 50px;
  margin-top: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.center-mode .transtart img {
  width: 40px;
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .center-mode .transtart {
    position: fixed;
    z-index: 21;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;
    box-sizing: border-box;
    flex-direction: column;
    height: unset;
    padding: 20px;
  }
  .center-mode .transtart img {
    width: 30px;
    margin: 0;
  }
}

.profile-form {
  width: 100%;
  max-width: 450px;
}

.profile-heading {
  display: flex;
  align-items: center;
}
.profile-heading .pic {
  background-color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.profile-heading .pic img {
  width: 100%;
  height: 100%;
}
.profile-heading button {
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
  min-width: 150px;
  text-transform: none;
  margin-top: 0;
  cursor: pointer;
}
.profile-heading button.outline {
  background-color: transparent;
  border: 1px solid #3da7db;
  color: #3da7db;
}
@media screen and (max-width: 768px) {
  .profile-heading {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .profile-heading .pic {
    width: 70px;
    height: 70px;
  }
  .profile-heading .pic img {
    width: 50px;
  }
  .profile-heading .name {
    margin-top: 20px;
    font-size: 14px;
  }
  .profile-heading span {
    font-size: 10px;
    color: #3da7db;
  }
}

.profile-input {
  margin-top: 10px;
}

.profile-item {
  display: flex;
  font-size: 12px;
  margin-bottom: 20px;
  align-items: center;
}
.profile-item .title {
  max-width: 100px;
  flex: 1 1;
  margin-right: 30px;
  text-align: left;
}
.profile-item .input-field {
  flex: 5 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 0.8px solid rgba(255, 255, 255, 0.6);
}
.profile-item .input-field input,
.profile-item .input-field select {
  width: 100%;
  background-color: transparent;
  padding: 0 20px;
  color: white;
  border: none;
  box-sizing: border-box;
}
.profile-item .input-field:focus-within {
  color: #3da7db;
  border-color: rgba(61, 167, 219, 0.6);
}
.profile-item .input-field:focus-within input,
.profile-item .input-field:focus-within select {
  color: #3da7db;
  outline: none;
}

.profile-save button {
  padding: 10px 35px !important;
  font-size: 13px;
  text-transform: none;
}
.profile-save p {
  font-size: 13px;
}

.contact h2 {
  font-size: 24px;
  font-weight: 500;
}
.contact .map img {
  width: 650px;
}
.contact .message-field {
  display: flex;
  width: 560px;
  margin: 0 auto;
}
.contact .message-com {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-right: 20px;
}
.contact .message-com input {
  margin-bottom: 20px;
  font-size: 11px;
  height: 30px;
  padding: 0 10px;
}
.contact .text {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
@media screen and (max-width: 768px) {
  .contact h2 {
    font-size: 15px;
  }
  .contact .message-field {
    width: unset;
    display: block;
    max-width: 250px;
  }
  .contact .message-com {
    margin-right: 0;
  }
  .contact button {
    max-width: 110px;
    margin: 0 auto;
    text-transform: none;
    margin-top: 20px;
  }
}

.transfer-input {
  display: block;
  min-width: 450px;
  max-width: 450px;
  background-color: white;
  width: 100%;
  height: 50px;
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .transfer-input {
    min-width: unset;
    height: 50px;
    max-width: 250px;
  }
}

.transtart.success {
  min-width: 768px;
  background-color: #30a82b;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .transtart.success {
    min-width: unset;
  }
}

.tranfer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tranfer button {
  text-transform: none;
}
.tranfer p {
  font-size: 14px;
  max-width: 700px;
}
.tranfer input {
  color: black !important;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .tranfer h2 {
    font-size: 14px;
  }
  .tranfer p {
    font-size: 10px;
    max-width: 300px;
  }
}

textarea {
  padding: 10px;
  font-size: 12px;
}

.smsItem {
  margin: 10px 0;
}

.link {
  color: #e3bf63;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0;
}

.staticContentContainer {
  margin: 10px 0;
  padding: 0 20px 20px;
  max-height: 75vh;
  overflow-y: auto;
  color: white;
}

.how-to-play {
  color: white;
}
.how-to-play .tab-body {
  height: 40vh;
  overflow-y: auto;
}

.colorWhite {
  color: white;
}

.provider-list {
  display: grid;
  max-width: 600px;
  width: 100%;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1.3em;
  margin-bottom: 50px;
}
@media screen and (max-width: 424px) {
  .provider-list {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
.provider-list .p-item {
  background-color: white;
  height: 120px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 2px;
  font-size: 14px;
  line-height: 15px;
  position: relative;
}
.provider-list .p-item span {
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .provider-list .p-item {
    font-size: 12px;
    padding: 10px;
  }
}
.provider-list .p-item img {
  width: 50px;
}
@media screen and (max-width: 424px) {
  .provider-list .p-item {
    font-size: 10px;
    min-width: 120px;
    padding: 8px;
  }
}
.provider-list .p-item .checker {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background-color: #088f50;
  color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.provider-list .p-item .checker span {
  margin-top: 0;
}

.sidebar {
  position: fixed;
  z-index: 1000000;
  width: 250px;
  background-color: #1b0f2c;
  top: 0;
  bottom: 0;
  right: -250px;
  transition: 0.5s ease;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
}
.sidebar .contain-side {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.sidebar .close {
  position: absolute;
  right: 20px;
  top: 50px;
}
.sidebar .close i {
  font-size: 30px;
  color: white;
}
.sidebar .profile-con {
  margin-top: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
.sidebar .profile-con a {
  color: white !important;
}
.sidebar .profile-con .img-con {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.sidebar .profile-con .img-con img {
  width: 50px;
}
.sidebar .profile-con p {
  margin: 0;
  font-size: 18px;
  color: white;
  position: relative;
  font-weight: 600;
}
.sidebar .profile-con small {
  display: block;
  font-size: 11px;
  color: white;
  position: relative;
}
.sidebar .main-link {
  list-style: none;
  margin: 0;
  margin-top: 30px;
  padding: 0;
  position: relative;
}
.sidebar .main-link a {
  color: white !important;
}
.sidebar .main-link li {
  padding: 10px 20px;
  color: white !important;
  font-size: 14px;
}
.sidebar .main-link li.hoverer:hover {
  background-color: #3da7db;
}
.sidebar .main-link li .dropdown {
  margin-top: 10px;
  display: none;
}
.sidebar .main-link li .dropdown .drop-item {
  font-size: 12px;
  padding: 10px 20px;
  color: white;
}
.sidebar .main-link li:hover .dropdown {
  display: block;
}
.sidebar .sub-links {
  margin-top: 20px;
  border-top: 1px solid white;
  position: relative;
}
.sidebar .sub-links a {
  color: white !important;
}
.sidebar .sub-links ol {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}
.sidebar .sub-links ol li {
  font-size: 12px;
  font-weight: 300;
  color: white;
  padding: 10px 30px;
}
.sidebar .footer {
  position: relative;
  margin-top: 50px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.sidebar .footer img {
  width: 100px;
}
.sidebar .footer small {
  font-size: 9px;
  color: white;
  font-weight: 300;
}
.sidebar .footer .ban {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  margin-bottom: 20px;
  background-color: #3da7db;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-holder {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background-color: black;
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  left: 0;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input-holder .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.input-holder .close svg {
  color: white;
}

.sidebar.open {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.sidebar.open .overlay {
  z-index: 1;
}
.sidebar.open .contain-side {
  z-index: 2;
}

.grid-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.fund-wallet {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .fund-wallet p {
    font-size: 11px !important;
    max-width: 150px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .fund-wallet p span {
    font-size: 15px;
    font-weight: 600;
  }
  .fund-wallet .fund-list {
    flex-direction: column;
  }
  .fund-wallet .fund-list .fund-item {
    margin: 0;
    height: 65px !important;
    width: 180px;
    margin-bottom: 15px;
  }
  .fund-wallet .fund-list .fund-item img {
    width: 40px !important;
  }
}

.raffle-main .mobile p {
  text-align: center;
  color: white;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .raffle-main .heading-dash input {
    background-color: white;
  }
}

.home-content {
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: space-between;
  height: 100%;
  min-height: 600px;
}
.home-content .home-bg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: -1;
}
.home-content .home-bg img {
  width: 50rem;
  transform: translateX(50px);
}
@media screen and (max-width: 1165px) {
  .home-content .home-bg img {
    width: 40rem;
  }
}
@media screen and (max-width: 920px) {
  .home-content .home-bg img {
    width: 30rem;
    transform: translateX(20px);
  }
}
@media screen and (max-width: 768px) {
  .home-content {
    flex-wrap: wrap;
    justify-content: center;
    min-height: 400px;
  }
  .home-content .home-bg img {
    width: 30rem;
    transform: translateX(0);
  }
}
@media screen and (max-width: 530px) {
  .home-content .home-bg img {
    width: 25rem;
  }
}
@media screen and (max-width: 400px) {
  .home-content .home-bg img {
    width: 20rem;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.footer-grid {
  display: flex;
  padding-bottom: 40px;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .footer-grid {
    display: grid;
  }
}
.footer-grid .footer-item {
  margin-right: 2em;
  margin-top: 2em;
}
.footer-grid .footer-item:last-child {
  margin-right: 0;
}
.footer-grid h3 {
  font-size: 14px;
  text-transform: uppercase;
  color: #ffbb3c;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
.footer-grid li {
  list-style: none;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-bottom: 7px;
}
.footer-grid .masterLogo {
  width: 100px;
  transform: translateY(-14px);
}
.footer-grid .paystackLogo {
  width: 200px;
  transform: translate(-38px, -70px);
}
.footer-grid .social-list {
  display: flex;
}
.footer-grid .social-list .social-icons {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
}
.footer-grid .icons {
  width: 30px;
  height: 30px;
}
.footer-grid .license {
  min-width: 350px;
}
.footer-grid .license li {
  display: flex;
  margin-bottom: 20px;
}
.footer-grid .license li p {
  margin: 0;
  margin-left: 10px;
  padding: 0;
}

.transferAuthList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 2em;
}
.transferAuthList .transferAuthCard {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  transition: 0.3s ease;
  border: 2px solid white;
}
.transferAuthList .transferAuthCard .bankName {
  font-size: 10px;
}
.transferAuthList .transferAuthCard .accNum {
  font-size: 25px;
  margin: 10px 0;
}
.transferAuthList .transferAuthCard:hover {
  border-color: #ffbb3c;
  cursor: pointer;
}

.linkButton {
  background-color: green;
  color: white;
  width: 100%;
  max-width: 300px;
  padding: 13px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
}

select {
  height: 50px;
  width: 100%;
  background-color: white;
  max-width: 300px;
  border: 1px solid white;
  text-align: center;
}

.orControl {
  margin: 10px auto;
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;
}
.orControl .item {
  height: 1px;
  background-color: white;
  flex: 1 1;
}
.orControl .key {
  padding: 0 10px;
  font-weight: 600px;
  margin-bottom: 3px;
}

.divider {
  height: 1px;
  width: 100%;
  max-width: 300px;
  background-color: white;
  margin: 10px 0;
}

.drawItem {
  margin-bottom: 20px;
}
.drawItem .focus {
  font-size: 26px;
}

/*# sourceMappingURL=default.css.map */

