.modalCustom {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
}
.modalCustom.show {
  z-index: 1000000;
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}
.modalCustom .close {
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
}
.modalCustom .close svg {
  width: 30px;
  height: 30px;
}
.modalCustom .modalCustom-content {
  position: relative;
  color: white;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
  box-sizing: border-box;
  min-height: 600px;
}

/*# sourceMappingURL=modalCustom.css.map */
