.countDown {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  padding: 20px 40px;
  box-sizing: border-box;
}
.countDown * {
  font-family: "Poppins", sans-serif;
}
.countDown::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../assets/blackFriday/countCover.png") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.countDown img {
  width: 100px;
}
@media screen and (max-width: 550px) {
  .countDown {
    margin: 0 20px;
    max-width: 400px;
  }
  .countDown::before {
    background-image: url("../../assets/blackFriday/countCoverV.png") !important;
    background-size: 150%;
  }
}
.countDown .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.countDown .head button {
  background-color: white;
  color: #000;
  border-radius: 0;
  font-size: 25px;
  font-weight: 600;
  width: unset;
  height: unset;
  padding: 5px 15px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .countDown .head {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }
  .countDown .head button {
    padding: 5px 12px;
    font-size: 18px;
  }
}
.countDown .contentMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 4vh;
}
@media screen and (max-width: 550px) {
  .countDown .contentMain {
    margin-top: 5vh;
  }
}
.countDown .context {
  margin: 20px 0;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}
@media screen and (max-width: 550px) {
  .countDown .context {
    font-size: 1rem;
    margin-bottom: 15px;
  }
}
.countDown .textBox {
  color: black;
  font-size: 0.9rem;
  padding: 3px 3vh;
  font-weight: 500;
  background-color: #f3b53e;
}
.countDown .timer {
  color: white;
  font-family: "DigitalFont", cursive !important;
  line-height: 3rem;
  font-style: italic;
  font-size: 6.5rem;
}
@media screen and (max-width: 550px) {
  .countDown .timer {
    font-size: 5.5rem;
  }
}
.countDown .winners {
  color: white;
  font-family: "DhanFont", cursive !important;
  font-size: 2rem;
  margin: 10px 0;
  margin-top: 5vh;
}
@media screen and (max-width: 550px) {
  .countDown .winners {
    font-size: 1.3rem;
    margin-top: 2vh;
  }
}
.countDown .textContent {
  margin-top: 5vh;
  margin-bottom: 5px;
  font-size: 20px;
  color: white;
}
@media screen and (max-width: 550px) {
  .countDown .textContent {
    font-size: 1rem;
    font-weight: 600;
  }
}
.countDown .playButt {
  border-radius: 50px;
  color: #ec424b;
  background: white;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 5vh;
}
@media screen and (max-width: 550px) {
  .countDown .playButt {
    width: 100%;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
.countDown .ribbon {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
}
.countDown .price {
  position: absolute;
  z-index: 1;
  width: 25vh;
  left: 0;
  bottom: 0;
}
@media screen and (max-width: 550px) {
  .countDown .price {
    position: relative;
    width: 22vh;
  }
}
.countDown .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
}
.countDown .logo {
  width: 70px;
}

/*# sourceMappingURL=countDown.css.map */
