.blackfriday {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 1000px;
  background-image: linear-gradient(140deg, #060606, #2a2426);
  padding: 40px;
  box-sizing: border-box;
}
.blackfriday * {
  font-family: "Poppins", sans-serif;
}
.blackfriday img {
  width: 100px;
}
@media screen and (max-width: 550px) {
  .blackfriday {
    margin: 0 20px;
    max-width: 400px;
    background-image: radial-gradient(#060606, #2a2426, #2a2426);
    background-size: cover;
    background-position: center;
  }
}
.blackfriday .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.blackfriday .head button {
  background-color: white;
  color: #000;
  border-radius: 0;
  font-size: 25px;
  font-weight: 600;
  width: unset;
  height: unset;
  padding: 5px 15px;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  .blackfriday .head {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }
  .blackfriday .head button {
    padding: 5px 12px;
    font-size: 18px;
  }
}
.blackfriday .contentMain {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
  position: relative;
}
.blackfriday .blackBallons {
  position: absolute;
  left: -10vh;
  top: 50%;
  transform: translateY(calc(-50% + 50px));
  width: 100%;
}
@media screen and (max-width: 550px) {
  .blackfriday .blackBallons {
    bottom: 0;
    top: unset;
    transform: unset;
    left: -5vh;
    width: 140%;
  }
}
.blackfriday .blackLogo {
  width: 15vh;
}
@media screen and (max-width: 550px) {
  .blackfriday .blackLogo {
    margin-top: 5vh;
  }
}
.blackfriday .textFormation {
  display: flex;
  margin-top: 3vh;
  color: white;
  align-items: center;
}
.blackfriday .textFormation .main {
  font-size: 5rem;
  margin-right: 10px;
  font-family: "Bebas Neue", cursive !important;
}
.blackfriday .textFormation .others {
  font-size: 2.2rem;
  line-height: 30px;
}
.blackfriday .textFormation .others * {
  font-family: "Bebas Neue", cursive !important;
}
@media screen and (max-width: 550px) {
  .blackfriday .textFormation .main {
    font-size: 4rem;
    margin-right: 5px;
  }
  .blackfriday .textFormation .others {
    font-size: 1.8rem;
    line-height: 25px;
  }
}
.blackfriday .blackCash {
  width: 40vh;
}
@media screen and (max-width: 550px) {
  .blackfriday .blackCash {
    width: 37vh;
  }
}
.blackfriday .context {
  margin: 5px 0;
  margin-bottom: 10px;
  color: white;
  font-family: "Bebas Neue", cursive !important;
  font-size: 2.6rem;
}
@media screen and (max-width: 550px) {
  .blackfriday .context {
    font-size: 1.8rem;
  }
}
.blackfriday .textBox {
  color: white;
  font-size: 1.2rem;
  padding: 0 3vh;
  font-weight: 600;
  background: linear-gradient(90deg, #b68135, #b68135, #fcd35f, #fcd35f, #cfc8b6, #c7903d, #c7903d, #c7903d, #c7903d, #6d4112, #c7903d, #c7903d);
}
.blackfriday .playButt {
  border-radius: 50px;
  color: #ec424b;
  background: white;
  margin-top: 5vh;
  font-weight: bold;
  font-size: 16px;
  padding: 7px 5vh;
}
@media screen and (max-width: 550px) {
  .blackfriday .playButt {
    margin-top: 15vh;
    width: 100%;
    font-weight: 500;
  }
}
.blackfriday .tc {
  color: white;
  margin-top: 3vh;
  font-size: 14px;
  font-weight: 500;
}
@media screen and (max-width: 550px) {
  .blackfriday .tc {
    margin-bottom: 20px;
  }
}
.blackfriday .logo {
  width: 70px;
}

/*# sourceMappingURL=blackFriday.css.map */
