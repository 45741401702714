.range-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.range-filter input {
  margin-right: 10px;
  background-color: white;
  color: black;
  height: 35px;
  width: 200px;
}

.range-filter label small {
  color: white;
  display: block;
  font-size: 10px;
  margin-bottom: 5px;
}
/*# sourceMappingURL=rangeFilter.css.map */